import React, {useContext, useEffect, useState} from "react";
import {Navigation} from "../navigation.jsx";
import {VendorsForCountry} from "../VendorsForCountry";
import {Footer} from "../Footer";
import {Gallery} from "../gallery";
import {useParams} from "react-router-dom";
import {StateContext} from "../../context/GlobalState";
import {GetText} from "../../data/lang";
import {Helmet} from "react-helmet";

export const CountryDetail = () => {

    // получение данных
    const {countryLink, lng} = useParams();
    const [countryDetail, setСountryDetail] = useState([]);
    const {lang} = useContext(StateContext);

    useEffect(() => {
        fetch(`https://muk.group/backend/api/countryDetail/read.php?country=${countryLink}`)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setСountryDetail(data)
            ) // save data to `state`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryLink])


   // let countryDetail = countryListDetail ? countryListDetail.filter(country => country.iso === countryLink) : countryListDetail;
  //  console.log(countryDetail);


    let text = GetText();

    return (
            <div>
                <Navigation lang={lng} />
                <div className="country menu-indent">
                {countryDetail.length > 0 ? countryDetail.map((country, i) =>
                    <div key={i} >
                    <Helmet htmlAttributes={{ lang : lang }}>
                        {lang === "ua" ? <title>MUK | {country.name_ua}</title> : ""}
                        {lang === "ru" ? <title>MUK | {country.name_ru}</title> : ""}
                        {lang === "en" ? <title>MUK | {country.name_en}</title> : ""}
                        {lang === "az" ? <title>MUK | {country.name_az}</title> : ""}
                        {lang === "al" ? <title>MUK | {country.name_al}</title> : ""}
                        {lang === "am" ? <title>MUK | {country.name_am}</title> : ""}
                        {lang === "ge" ? <title>MUK | {country.name_ge}</title> : ""}
                        {lang === "kz" ? <title>MUK | {country.name_kz}</title> : ""}
                        {lang === "kg" ? <title>MUK | {country.name_kg}</title> : ""}
                        {lang === "mk" ? <title>MUK | {country.name_mk}</title> : ""}
                        {lang === "md" ? <title>MUK | {country.name_md}</title> : ""}
                        {lang === "mn" ? <title>MUK | {country.name_mn}</title> : ""}
                        {lang === "si" ? <title>MUK | {country.name_si}</title> : ""}
                        {lang === "tj" ? <title>MUK | {country.name_tj}</title> : ""}
                        {lang === "tr" ? <title>MUK | {country.name_tr}</title> : ""}
                        {lang === "tm" ? <title>MUK | {country.name_tm}</title> : ""}
                        {lang === "uz" ? <title>MUK | {country.name_uz}</title> : ""}
                        {lang === "hr" ? <title>MUK | {country.name_hr}</title> : ""}
                        {lang === "rs" ? <title>MUK | {country.name_rs}</title> : ""}
                        {lang === "bg" ? <title>MUK | {country.name_bg}</title> : ""}
                        <meta name="description" content={country.description_ua.substr(0, 200)} />
                        <meta name="keywords"  content='country, muk' />

                    </Helmet>

                    <div className="country-header">
                        <div className="container">
                            <img  src={`/img/flags/${country.img}`} title={country.img} alt="country flag" className="country-img"/>
                            <p className="country-name col-md-9">
                                {lang === "ua" ? country.name_ua : ""}
                                {lang === "ru" ? country.name_ru : ""}
                                {lang === "en" ? country.name_en : ""}
                                {lang === "az" ? country.name_az : ""}
                                {lang === "al" ? country.name_al : ""}
                                {lang === "am" ? country.name_am : ""}
                                {lang === "ge" ? country.name_ge : ""}
                                {lang === "kz" ? country.name_kz : ""}
                                {lang === "kg" ? country.name_kg : ""}
                                {lang === "mk" ? country.name_mk : ""}
                                {lang === "md" ? country.name_md : ""}
                                {lang === "mn" ? country.name_mn : ""}
                                {lang === "si" ? country.name_si : ""}
                                {lang === "tj" ? country.name_tj : ""}
                                {lang === "tr" ? country.name_tr : ""}
                                {lang === "tm" ? country.name_tm : ""}
                                {lang === "uz" ? country.name_uz : ""}
                                {lang === "hr" ? country.name_hr : ""}
                                {lang === "rs" ? country.name_rs : ""}
                                {lang === "bg" ? country.name_bg : ""}
                            </p>
                        </div>
                    </div>
                    <div className="contact_information">
                        <div className="container">
                            {lang === "ua" ? <p><b>{country.adress_ua ? country.adress_ua : "Адрес"}</b></p> : ""}
                            {lang === "ru" ? <p><b>{country.adress_ru ? country.adress_ru : "Адрес"}</b></p> : ""}
                            {lang === "en" ? <p><b>{country.adress_en ? country.adress_en : "Адрес"}</b></p> : ""}
                            {lang === "az" ? <p><b>{country.adress_az ? country.adress_az : "Адрес"}</b></p> : ""}
                            {lang === "al" ? <p><b>{country.adress_al ? country.adress_al : "Адрес"}</b></p> : ""}
                            {lang === "am" ? <p><b>{country.adress_am ? country.adress_am : "Адрес"}</b></p> : ""}
                            {lang === "ge" ? <p><b>{country.adress_ge ? country.adress_ge : "Адрес"}</b></p> : ""}
                            {lang === "kz" ? <p><b>{country.adress_kz ? country.adress_kz : "Адрес"}</b></p> : ""}
                            {lang === "kg" ? <p><b>{country.adress_kg ? country.adress_kg : "Адрес"}</b></p> : ""}
                            {lang === "mk" ? <p><b>{country.adress_mk ? country.adress_mk : "Адрес"}</b></p> : ""}
                            {lang === "md" ? <p><b>{country.adress_md ? country.adress_md : "Адрес"}</b></p> : ""}
                            {lang === "mn" ? <p><b>{country.adress_mn ? country.adress_mn : "Адрес"}</b></p> : ""}
                            {lang === "si" ? <p><b>{country.adress_si ? country.adress_si : "Адрес"}</b></p> : ""}
                            {lang === "tj" ? <p><b>{country.adress_tj ? country.adress_tj : "Адрес"}</b></p> : ""}
                            {lang === "tr" ? <p><b>{country.adress_tr ? country.adress_tr : "Адрес"}</b></p> : ""}
                            {lang === "tm" ? <p><b>{country.adress_tm ? country.adress_tm : "Адрес"}</b></p> : ""}
                            {lang === "uz" ? <p><b>{country.adress_uz ? country.adress_uz : "Адрес"}</b></p> : ""}
                            {lang === "hr" ? <p><b>{country.adress_hr ? country.adress_hr : "Адрес"}</b></p> : ""}
                            {lang === "rs" ? <p><b>{country.adress_rs ? country.adress_rs : "Адрес"}</b></p> : ""}
                            {lang === "bg" ? <p><b>{country.adress_bg ? country.adress_bg : "Адрес"}</b></p> : ""}


                            <p className="contact-phone">{country.telefon ? country.telefon : "Телефон"}</p>
                            <p>{country.email ? <a href={`mailto:${country.email}`}>{country.email}</a> : "Почта"}</p>
                            <p className="contact-b2b">
                                {country.b2b_link ? (
                                    <a target="_blank" rel="noopener noreferrer" href={country.b2b_link}>
                                        {country.b2b_link.replace(/^https?:\/\//, '')}
                                    </a>
                                ) : (
                                    ''
                                )}
                            </p>
                        </div>
                    </div>

                <div className="country-info">
                    {lang === "ua" ?
                    <div className="container">{country.description_ua ? <div dangerouslySetInnerHTML={{__html: country.description_ua}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "ru" ?
                        <div className="container">{country.description_ru ? <div dangerouslySetInnerHTML={{__html: country.description_ru}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "en" ?
                        <div className="container">{country.description_en ? <div dangerouslySetInnerHTML={{__html: country.description_en}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "az" ?
                        <div className="container">{country.description_az ? <div dangerouslySetInnerHTML={{__html: country.description_az}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "al" ?
                        <div className="container">{country.description_al ? <div dangerouslySetInnerHTML={{__html: country.description_al}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "am" ?
                        <div className="container">{country.description_am ? <div dangerouslySetInnerHTML={{__html: country.description_am}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "ge" ?
                        <div className="container">{country.description_ge ? <div dangerouslySetInnerHTML={{__html: country.description_ge}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "kz" ?
                        <div className="container">{country.description_kz ? <div dangerouslySetInnerHTML={{__html: country.description_kz}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "kg" ?
                        <div className="container">{country.description_kg ? <div dangerouslySetInnerHTML={{__html: country.description_kg}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "mk" ?
                        <div className="container">{country.description_mk ? <div dangerouslySetInnerHTML={{__html: country.description_mk}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "md" ?
                        <div className="container">{country.description_md ? <div dangerouslySetInnerHTML={{__html: country.description_md}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "mn" ?
                        <div className="container">{country.description_mn ? <div dangerouslySetInnerHTML={{__html: country.description_mn}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "si" ?
                        <div className="container">{country.description_si ? <div dangerouslySetInnerHTML={{__html: country.description_si}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "tj" ?
                        <div className="container">{country.description_tj ? <div dangerouslySetInnerHTML={{__html: country.description_tj}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "tr" ?
                        <div className="container">{country.description_tr ? <div dangerouslySetInnerHTML={{__html: country.description_tr}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "tm" ?
                        <div className="container">{country.description_tm ? <div dangerouslySetInnerHTML={{__html: country.description_tm}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "uz" ?
                        <div className="container">{country.description_uz ? <div dangerouslySetInnerHTML={{__html: country.description_uz}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "hr" ?
                        <div className="container">{country.description_hr ? <div dangerouslySetInnerHTML={{__html: country.description_hr}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "rs" ?
                        <div className="container">{country.description_rs ? <div dangerouslySetInnerHTML={{__html: country.description_rs}}/> : text.Vendors.notInfo} </div> : ""}

                    {lang === "bg" ?
                        <div className="container">{country.description_bg ? <div dangerouslySetInnerHTML={{__html: country.description_bg}}/> : text.Vendors.notInfo} </div> : ""}

                </div>
                    <VendorsForCountry countryId={country.id} />
            </div> ) :  "Loading..."}
                </div>
                <Gallery />
                <Footer/>

            </div>

    );
};
